import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Mailchimp from 'react-mailchimp-form'

import { Section, Container } from '@components/global';
// import ExternalLink from '@common/ExternalLink';
const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "tell_story" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="join-us" accent>
        <StyledContainer>
          <div>
            <h1>Join our community</h1>
            <p style={{paddingTop: '40px'}}>
            We are currently offering free workshops to support talking politics 
            with family and friends. Reach out if you are interested in bringing it to your organization! 
            We welcome people of all backgrounds and perspectives.</p>
            <div style={{paddingTop: '20px'}}>
              <a target="_blank" href="mailto:effectivedialogueproject@gmail.com">
                <CTAButton>Contact Us</CTAButton>
              </a>
            </div>
            <div style={{paddingTop: '40px'}}>
              <p>
                To stay in the loop, sign up for our updates here:
              </p>
              <MailchimpWrapper>
                <Mailchimp
                  action='https://effectivedialogue.us1.list-manage.com/subscribe/post?u=e8048aba55ac18ced58766f2f&amp;id=0a2bdd4b21'
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: 'Email',
                      type: 'email',
                      required: true
                    }
                  ]}
                    messages = {
                    {
                      sending: "Sending...",
                      success: "Thank you for subscribing!",
                      error: "An unexpected internal error has occurred.",
                      empty: "Please enter an email.",
                      duplicate: "You've already subscribed! Thank you.",
                      button: "Subscribe"
                    }
                  }
                />
              </MailchimpWrapper>
            </div>
          </div>
          <Art>
            <Img fluid={data.art_story.childImageSharp.fluid} />
          </Art>
        </StyledContainer>
      </Section>
    )}
  />
);

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;



const CTAButton = styled.button`
  color: #494949 !important;
  text-transform: uppercase;
  background: #ffffff;
  padding: 20px;
  border: 4px solid #494949 !important;
  display: inline-block;
  font-size: 20px;
`;


const Art = styled.figure`
  width: 1000px;
  top: -12%;
  right: 50%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 0;
    right: 65%;
    width: 500px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const MailchimpWrapper = styled(Container)`
  padding-top: 20px;
  font: inherit;

  button {
    color: #494949 !important;
    background: #ffffff;
    padding: 9px;
    border: 2px solid #494949 !important;
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    margin-left: 14px;
    margin-top: -2px;
  }
  
  input {
    border: 1px solid #494949 !important;
    width: 65%;
    height: 36px;
    margin-left: -16px;
    padding: 10px;
    font-size: 16px;
    line-height: 16px;
    border-radius: 5px;
  }

  p {
    color: rgb(212 169 102) !important;
    margin-left: -16px;
    ${props => props.theme.font_size.small};
  }
`;


export default UsedBy;
