import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

const About = () => (
  <Section id="about">
    <Container>
        <div>
          <h2>America is more polarized than ever.</h2>
          <p>
            Democracy relies on different sides coming together, but 
            it seems increasingly impossible in this day and age.
            We have disparate news sources and social media algorithms 
            that filter our realities, profiting off of fear and anger. 
            A 2016 <a href="https://psyarxiv.com/d6bpe/">study</a> showed 
            that people on one side of the aisle thought that the other 
            hated them more than they <em>actually</em> did. 
          </p>
          </div>
          <div style={{paddingTop: '30px'}}>
          <h2>Political conversations are <em>hard</em>.</h2>
          <p>
            It's often easier to avoid engaging with people
            we disagree with, full stop. And when we do, the experience can be 
            frustrating and exhausting, especially with friends and family. We 
            might feel dismissed, misunderstood, or judged—and wish 
            that we'd never gotten into it in the first place.
          </p>
        </div>
        <div style={{paddingTop: '30px'}}>
          <h2>We're creating a world where conversations thrive.</h2>
          <p>
            We believe that positive and fulfilling political conversations—even across 
            differences—are possible. We are a community of practice that supports 
            each other to cultivate an effective toolkit for difficult 
            dialogue. We hope that greater connection and mutual understanding 
            will strengthen our society, leading to better outcomes for all.
          </p>
        </div>
    </Container>
  </Section>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
